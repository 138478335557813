<template>
  <li class="events list_tickets_item d-flex" @click="selectTicket">
    <div class="m-auto">
      <b-form-radio v-if="index!==-1" v-model="selectedIdx" :value="index" class="ticket-select ml-3" size="lg"></b-form-radio>
    </div>
    <div class="events tickets_maininfo_group m-auto">
      <div class="event tickets_name_txt">
        <font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{labelClass}}</font></font>
        </div>
      <div class="tickets_place_group w-clearfix">
        <div class="events tickets_place_txt">
          <font style="vertical-align: inherit;"><font class="" style="vertical-align: inherit;">{{labelType}}</font></font>
        </div>
      </div>
    </div>
    <div class="events tickets_price_order_group">
      <div class="tickets_number_group" v-if="device === 'desktop'">
        <b-form-spinbutton
          v-model="numOfTickets"
          min="0"
          :max="maxNumber"
          step="1"
          dir="ltr"
        ></b-form-spinbutton>
      </div>
      <div class="events_price_infogroup">
        <div class="events tickets_price_group">
          <div class="tickets_price_currency"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{labelPrice}}</font></font>
          </div>
        </div>
        <div class="events events_price_infotxt"><font style="vertical-align: inherit;">
          <font style="vertical-align: inherit;"></font></font>
        </div>
      </div>
    </div>
    <div class="tickets_number_group mr-2 ml-2" v-if="device === 'mobile'">
        <b-form-spinbutton
          vertical
          v-model="numOfTickets"
          min="0"
          :max="maxNumber"
          step="1"
          size="sm"
          dir="ltr"
        ></b-form-spinbutton>
      </div>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';
import { BFormSpinbutton, BFormRadio } from 'bootstrap-vue';
import gMixin from '@/utils/mixins';

export default {
  name: 'TicketDetail',
  components: {
    BFormSpinbutton, BFormRadio,
  },
  props: {
    ticket: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: -1,
    },
    selectedIdx: {
      type: Number,
      default: 0,
    },
    defaultNum: {
      type: Number,
      default: 0,
    },
  },
  mixins: [gMixin],
  data() {
    return {
      numOfTickets: 0,
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
    }),
    labelClass() {
      return this.ticket?.labelClass?.[this.lang] ?? this.ticket.room_class;
    },
    labelType() {
      // return this.ticket.room_type;
      return this.ticket.Htl_Name;
    },
    labelPrice() {
      return this.getPriceWithSymbol(this.ticket.cc, this.ticket.price);
    },
    maxNumber() {
      return 10;
    },
  },
  watch: {
    numOfTickets() {
      this.$emit('change', { index: this.index, numOfTickets: this.numOfTickets });
    },
    selectedIdx() {
      if (this.index !== this.selectedIdx) {
        this.numOfTickets = 0;
      } else {
        this.numOfTickets = this.defaultNum;
      }
    },
  },
  async created() {
    if (this.index === this.selectedIdx) this.numOfTickets = this.defaultNum;
  },
  methods: {
    selectTicket() {
      if (this.selectedIdx !== this.index) {
        this.$emit('change', { index: this.index, numOfTickets: this.defaultNum });
      }
    },
  },
};

</script>

<style>
  .tickets_maininfo_group.events {
    display: block;
    width: 56%;
    padding-right: 0;
    padding-left: 0;
    border-right-style: none;
  }
  .tickets_number_group{
    display: flex;
  }
  .events.tickets_price_order_group {
    width: 40% !important;
    padding: 3px;
    align-self: center;
  }
  .tickets_number_group .form-control.focus {
    color: #495057;
    background-color: #fff;
    border-color: #f57e20;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #f57c2038;
  }
  .tickets_number_group .b-form-spinbutton button{
    align-items: center;
    background-color: #f57e20;
    color: #fff;
  }
</style>
